
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import { LabelValue } from "@/types";

import VueTaggableSelect from "@/components/VueTaggabbleSelect.vue";
import { capitalize } from "@/helpers/commons";

@Component({
    name: "SelectAutocomplete",
    components: { VueTaggableSelect },
})
export default class SelectAutocomplete extends mixins(VModel) {
    $refs!: {
        select: any;
    };

    @Prop() readonly placeholder!: string;
    @Prop() readonly searchKey!: string;
    @Prop(Boolean) readonly searchArray!: boolean;
    @Prop() readonly name!: string;
    @Prop() options!: any;
    @Prop({ type: Boolean, default: true }) readonly taggable!: boolean;
    @Prop({ type: Boolean, default: false }) readonly showIcons!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideName!: boolean;
    @Prop({ type: Boolean, default: false }) readonly required!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isDeletedOptionDisable!: boolean;
    @Prop(Boolean) readonly inner!: boolean;

    // onSelect() {
    //     this.$refs.select.$el.querySelector('input').blur();
    // }

    displayLabel(option: LabelValue) {
        return option && capitalize(option.label);
    }

    getOptionValue(option: LabelValue) {
        return option && option.value;
    }
}
